<template>
  <div>
    <v-toolbar
      rounded
      tile
    >
      <!-- BEGIN menu for smartphones -->
      <v-menu
        open-on-hover
        transition="slide-x-transition"
        bottom
        right
        offset-y
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="hidden-md-and-up"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-card
          class="mx-auto"
          max-width="300"
          tile
        >
          <v-list dense>
            <v-list-item-group
              v-model="mini"
              color="primary"
            >
              <v-list-item
                v-for="(miniitem0, i) in section0"
                :key="`section0-${i}`"
                :to="miniitem0.link"
              >
                <template v-if="miniitem0.condition === undefined || miniitem0.condition() === true">
                  <v-list-item-action>
                    <v-icon v-text="miniitem0.icon" />
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-list-item-title v-text="$t(miniitem0.text)" />
                  </v-list-item-action>
                </template>
              </v-list-item>
              <v-divider />
              <v-list-item
                v-for="(miniitem1, i) in section1"
                :key="`section1-${i}`"
                :to="miniitem1.link"
              >
                <v-list-item-action>
                  <v-icon v-text="miniitem1.icon" />
                </v-list-item-action>
                <v-list-item-action>
                  <v-list-item-title v-text="$t(miniitem1.text)" />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-if="userType !== ANONYMOUS"
              >
                <amplify-sign-out />
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
      <!-- END menu for smartphones -->
      <!-- BEGIN logo -->
      <v-container
        fluid
      >
        <router-link
          :to="{ name: homePage.link.name }"
        >
          <base-img
            :src="require('@/assets/KIDIWI-services-gradient.svg')"
            alt="kidiwi logo"
            class="ml-3 mt-3 mb-3"
            contain
            max-width="200"
            max-height="80"
          />
        </router-link>
      </v-container>
      <!-- END logo -->
      <v-spacer />
      <!-- BEGIN menu for small and big screens -->
      <v-divider
        class="mx-4"
        vertical
      />
      <v-toolbar-items
        v-for="(topitem, index) in section0"
        :key="index"
        class="hidden-sm-and-down"
      >
        <template v-if="topitem.condition === undefined || topitem.condition() === true">
          <v-btn
            :id="topitem.link.name"
            text
            :to="{ name: topitem.link.name }"
          >
            {{ $t(topitem.text) }}
          </v-btn>
          <v-divider
            class="mx-4"
            vertical
          />
        </template>
      </v-toolbar-items>
      <!-- END menu for small and big screens -->
      <kdw-locale-switcher />
      <v-divider
        class="mx-4"
        vertical
      />
      <!--
      <v-btn
        text
      >
        "{{ userType }}"
      </v-btn>
      -->
      <v-toolbar-items
        class="hidden-sm-and-down"
      >
        <!-- BEGIN user signed in -->
        <v-menu
          v-if="userType !== ANONYMOUS"
          open-on-hover
          transition="slide-x-transition"
          bottom
          right
          offset-y
          :rounded="rounded"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              id="SideMenu"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-account
              </v-icon>
            </v-btn>
          </template>
          <v-card
            class="mx-auto"
            max-width="300"
            tile
          >
            <v-list dense>
              <v-list-item-group
                v-model="side"
                color="primary"
              >
                <v-list-item
                  v-for="(sideitem, i) in section1"
                  :key="i"
                  :to="sideitem.link"
                  :id="sideitem.id"
                >
                  <v-list-item-action>
                    <v-icon v-text="sideitem.icon" />
                  </v-list-item-action>
                  <v-list-item-action>
                    <!--  :id="sideitem.text"  --->
                    <v-list-item-title
                      v-text="$t(sideitem.text)"
                    />
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <amplify-sign-out />
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
        <!-- END user signed in -->
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator'
  import { Logger } from 'aws-amplify'
  import { ANONYMOUS, ENDUSER, SERVICEPROVIDER, getUserType, onUserTypeChange } from '@/services/User'
  import { navigate } from '@/helpers/Navigation'
  const logger = new Logger('Menu')

  @Component({
    name: 'Menu',
  })
  class Menu extends Vue {
    ANONYMOUS = ANONYMOUS
    ENDUSER = ENDUSER
    SERVICEPROVIDER = SERVICEPROVIDER
    isProLanding = false

    created () {
      this.ANONYMOUS = ANONYMOUS
      this.ENDUSER = ENDUSER
      this.SERVICEPROVIDER = SERVICEPROVIDER
      this.userType = getUserType()
      this.unsubscribeUserType = onUserTypeChange((newUserType) => {
        logger.info('changing userType ', newUserType)
        this.userType = newUserType
      })
    }

    @Watch('userType')
    onUserTypeChange (newV, oldV) {
      logger.info(`changed userType from ${oldV} to ${newV}`)
      // if we new an old state (enduser or serviceprovider) and we go back to anonymous
      if (oldV && newV === ANONYMOUS) {
        navigate(this, 'Home')
      }
    }

    data () {
      // probably dont want a function here
      return {
        ENDUSER: ENDUSER,
        ANONYMOUS: ANONYMOUS,
        SERVICEPROVIDER: SERVICEPROVIDER,
        userType: null,
        homePage: {
          link: {
            name: 'Home',
          },
        },
      }
    }

    beforeDestroy () {
      this.unsubscribeUserType()
    }
  }
  export default Menu

</script>

<style scoped>
  amplify-sign-out {
    --amplify-primary-color: #E4007A;
    --amplify-primary-tint: rgba(228,0,112,0.9);
    --amplify-primary-shade: rgba(228,0,122,0.7);
  }
</style>
